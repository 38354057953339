import React from "react";
import PropTypes from "prop-types";
import BrandingLinks from "../components/BrandingLinks";
import ResourcesLinks from "../components/ResourcesLinks";
import AccessibilityLinks from "../components/AccessibilityLinks";
import DevelopmentLinks from "./DevelopmentLinks";
import AboutLinks from "../components/AboutLinks";
import { Nav } from "react-bootstrap";
import "../styles/sidebar.sass";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuLinks: props.menuLinks,
      siteTitle: props.siteTitle,
      active: false,
      sidebarActiveClass: "",
      dropdownOpen: false
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the sidebar accordingly
        this.state.active
          ? this.setState({
              sidebarActiveClass: "is-active"
            })
          : this.setState({
              sidebarActiveClass: ""
            });
      }
    );
  };

  collapseSidebar = () => {
    console.log('collapsing sidebar');
  }

  render() {
    return (
      <nav
        className="sidebar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="sidebar-brand">
          <div
            id="navMenu"
            className={`sidebar-menu ${this.state.sidebarActiveClass}`}
          >
            <div className="sidebar-end has-text-centered">
              <Nav>
                {/* Loops through each of the routes defined in gatsby-config.js */}
                {this.state.menuLinks.map(route => {
                  // First if statement loops through for all top-level pages that aren't the home page
                  if (
                    typeof window !== "undefined" &&
                    window.location.href.indexOf(route.link) > -1 &&
                    route.name !== "Home"
                  ) {
                    // if the route has child elements, render the sidebar
                    if (route.children) {
                      return (
                        <React.Fragment key={route.name}>
                          <h3 className="sidebar-parent-name" key="header">
                            {route.name}
                          </h3>
                          {/* This will add an overview page for all pages that contain child pages */}
                          <Nav.Link
                            key={route.name}
                            className="sidebar-nav-link child-nav-item"
                            href={route.link}
                            active={
                              typeof window !== "undefined" &&
                              (window.location.pathname === route.link ||
                                window.location.pathname ===
                                  route.link.concat("/"))
                            }
                          >
                            Overview
                          </Nav.Link>
                          {/* This will automatically add all links that are statically declared from gatsby-config.js */}
                          {route.children.map(child => {
                            return (
                              <Nav.Link
                                key={child.name}
                                className="sidebar-nav-link child-nav-item"
                                href={route.link.concat(child.link)}
                                active={
                                  typeof window !== "undefined" &&
                                  window.location.href.indexOf(child.link) > -1
                                }
                              >
                                {child.name}
                              </Nav.Link>
                            );
                          })}
                          {/* The following lines will add dynamic links that are generated inside the CMS */}
                          {/* {route.name === "Home" && <HomePageLinks />} */}
                          {route.name === "About" && <AboutLinks />}
                          {route.name === "Accessibility" && (
                            <AccessibilityLinks />
                          )}
                          {route.name === "Branding" && <BrandingLinks />}
                          {route.name === "Development" && <DevelopmentLinks />}
                          {route.name === "Resources" && <ResourcesLinks />}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <h3 className="sidebar-parent-name" key="header">
                          {route.name}
                        </h3>
                      );
                    }
                    // Second if statement renders top links sidebar for the home page
                  } else if (
                    route.name === "Home" &&
                    typeof window !== "undefined" &&
                    window.location.pathname === route.link
                  ) {
                    return (
                      <React.Fragment key="home">
                        <h3 className="sidebar-parent-name" key="header">
                          Top Links
                        </h3>
                        <Nav.Link
                          key="about"
                          className="sidebar-nav-link child-nav-item"
                          href="/about"
                        >
                          Getting Started
                        </Nav.Link>
                        <Nav.Link
                          key="accessibility"
                          className="sidebar-nav-link child-nav-item"
                          href="/accessibility"
                        >
                          Accessibility
                        </Nav.Link>
                        <Nav.Link
                          key="colors"
                          className="sidebar-nav-link child-nav-item"
                          href="/branding/colors"
                        >
                          Colors
                        </Nav.Link>
                        <Nav.Link
                          key="email-signature-generator"
                          className="sidebar-nav-link child-nav-item"
                          href="/resources/email-signature-generator"
                        >
                          Email Signature Generator
                        </Nav.Link>
                        <Nav.Link
                          key="logos"
                          className="sidebar-nav-link child-nav-item"
                          href="/branding/logos"
                        >
                          Logos
                        </Nav.Link>
                        <Nav.Link
                          key="request-features"
                          className="sidebar-nav-link child-nav-item"
                          href="/contact"
                        >
                          Request New Features
                        </Nav.Link>
                        <Nav.Link
                          key="contribute"
                          className="sidebar-nav-link child-nav-item"
                          href="https://github.com/derek3play/3play-design-system"
                          target="__blank"
                        >
                          Contribute to Repo
                        </Nav.Link>
                      </React.Fragment>
                    );
                  } else {
                    return <React.Fragment key={route.name}></React.Fragment>;
                  }
                })}
                
              </Nav>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string
};

Sidebar.defaultProps = {
  siteTitle: ``
};

export default Sidebar;
