import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { Nav } from "react-bootstrap";

class BrandingLinks extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: pages } = data.allMarkdownRemark;
    return (
      <React.Fragment>
        {pages &&
          pages.map(({ node: page }) => {
            if (page.frontmatter.title !== "Branding") {
              return (
                <Nav.Link
                  key={page.frontmatter.title}
                  className="sidebar-nav-link"
                  href={page.fields.slug}
                  active={typeof window !== 'undefined' && window.location.href.indexOf(page.fields.slug) > -1}
                >
                  {page.frontmatter.title}
                </Nav.Link>
              );
            }
            return "";
          })}
      </React.Fragment>
    );
  }
}

BrandingLinks.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query BrandingLinksQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "branding-page" } } }
          sort: {
            fields: [frontmatter___title]
            order: ASC
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BrandingLinks data={data} count={count} />}
  />
);
