import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import "../styles/navbar.sass";
import logo from "../img/design-system-logo.png";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuLinks: props.menuLinks,
      siteTitle: props.siteTitle,
      active: false,
      navbarActiveClass: ""
    };
  }

  updateNavbar() {
    if (window.innerWidth < 1024) {
      document.getElementById("Nav.Links").style.display = "none";
    } else {
      document.getElementById("Nav.Links").style.display = "block";
    }
  }

  componentDidMount() {
    this.updateNavbar();
    window.addEventListener("resize", this.updateNavbar.bind(this));
    if (window.docsearch) {
      window.docsearch({
        apiKey: '3aa192d05eb65457377f715359ca270e',
        indexName: '3playground',
        inputSelector: '#search-box',
        debug: true // Set debug to true if you want to inspect the dropdown
      });
    } else {
      console.warn('Search has failed to load and now is being disabled');
      this.setState({enabled: false});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateNavbar.bind(this));
  }

  toggleNavbar() {
    var x = document.getElementById("Nav.Links");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  render() {
    return (
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-logo" title="Logo">
            <img src={logo} alt="3Play Media Logo" />
          </Link>
        </div>
        <button className="navbar-hamburger-icon" onClick={this.toggleNavbar}>
          <i className="fa fa-bars"></i>
        </button>
        <div className="navbar-menu">
          <div className="navbar-end has-text-centered">
            <div id="Nav.Links">
            <input id='search-box' type="search" className="search-box" placeholder="Search Site..." autoFocus />
              {this.state.menuLinks.map(route => {
                if (route.name !== "Home") {
                  return (
                    <React.Fragment key={route.name}>
                      <Nav.Link
                        id={route.name}
                        className="top-nav-link parent-nav-item"
                        href={route.link}
                        active={typeof window !== "undefined" && window.location.href.indexOf(route.link) > -1}
                      >
                        {route.name}
                      </Nav.Link>
                    </React.Fragment>
                  );
                }
                // Don't show Home as a navigation item in the header
                return <React.Fragment key={route.name}></React.Fragment>;
              })}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  siteTitle: PropTypes.string
};

Navbar.defaultProps = {
  siteTitle: ``
};

export default Navbar;
