import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="row">
              <div className="col-4">
                <section className="menu">
                  <ul className="footer-list">
                    <li>
                      <Link to="/" className="footer-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/branding">
                        Branding
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="col-4">
                <section>
                  <ul className="footer-list">
                    <li>
                      <a
                        className="footer-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin Portal
                      </a>
                    </li>
                    <li>
                      <Link className="footer-item" to="/resources">
                        Resources
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="col-4">
                <section>
                  <ul className="footer-list">
                    <li>
                      <Link
                        className="footer-item github-logo"
                        to="/cms-help"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                        <span>View CMS Template Help</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="footer-item github-logo"
                        to="/contact"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        <span>Request New Features</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        className="footer-item github-logo"
                        href="https://github.com/derek3play/3play-design-system"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-github" aria-hidden="true"></i>
                        <span>Contribute to Repo</span>
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
