import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Button, Modal } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      show: false
    }
  }

  render() {
    const overview = (
      <>
        <p>
          Modals are disruptive components that can cause frustration and create interruptions in a user's workflow. Modals should be used sparingly under specific circumstances, and should not be overused in order provide an optimal user experience. 
        </p>
        <p>
          Modals should never be used to solely present information. If you want to solely present information, consider using a tooltip or presenting the information visibly and clearly next to the content of interest.
        </p>
        <p>
          Instead, the purpose of a modal is to prompt a user to review a potentially destructive action or perform an activity that advances a user workflow.
        </p>
        <p>
          A modal is composed of a header, content, and footer section. The header should contain a succinct message of what the modal will do. The content area should present the information clearly and concisely, as well as in the correct format (chart, table). If the content area is too long, make sure users know that they can scroll to get to content below the fold. The footer should always have at least a primary and secondary button, and an optional tertiary button. 
        </p>
        <p>
          Primary buttons should be used to highlight the main action a user can perform on the modal. Secondary modal buttons should primarily be used to close out of the modal, but in special cases can allow a user to edit details or go back to a previous step. Tertiary buttons should be rarely used unless the workflow requires a user to complete a specific action before they can continue, as they induce more cognitive load and frustration.
        </p>
        {/* <p>
          <b>Header: </b>The header should contain a succinct message of what the modal will do.
          <br /><b>Examples: </b>"Move File" or "Delete File?"
        </p>
        <p>
          <b>Content Area: </b>The content area should present the information clearly and concisely. If presenting text, make sure that there is not too much text. If presenting data, make sure that the information is laid out clearly and in the correct format (chart, table). If the content area is too long, make sure users know that they can scroll to get to content below the fold.
        </p>
        <p>
          <b>Footer: </b>The footer should contain no more than 3 buttons. A modal should always have at least a primary and secondary button, and an optional tertiary button. Primary buttons should be used to highlight the main action a user can perform on the modal. Secondary modal buttons should primarily be used to close out of the modal, but in special cases can allow a user to edit details or go back to a previous step. Tertiary buttons should be rarely used unless the workflow requires a user to complete a specific action before they can continue, as they induce more cognitive load and frustration.
          <br /><b>Primary Button Copy Examples: </b>"Move File", Delete File", "Cancel Service"
          <br /><b>Secondary Button Copy Examples: </b>"Cancel", "Go Back", "Learn More"
          <br /><b>Tertiary Button Copy Examples: </b>"Manage Folders" or "Edit Settings"
        </p> */}
      </>
    );

    const code_basic = (
      <>
        <br />
        <h3>Basic Modal</h3>
        <p>Below is a static modal dialog (without the positioning) to demonstrate the look and feel of the Modal.</p>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Modal body text goes here.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" className="mr-auto">Tertiary</Button>
            <Button variant="secondary">Close</Button>
            <Button variant="primary">Submit</Button>
          </Modal.Footer>
        </Modal.Dialog>
        <br />
      </>
    );
    const codeString_basic = `
    <Modal.Dialog>
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Modal body text goes here.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button constiant="secondary" className="mr-auto">Tertiary</Button>
        <Button constiant="secondary">Close</Button>
        <Button constiant="primary">Submit</Button>
      </Modal.Footer>
    </Modal.Dialog>
    `;

    const code_button = (
      <>
        <br />
        <h3>Modal triggered by button</h3>
        <p>This would be the most common use case for Modals, to be triggered via a button click. The show and display of the modal is handled by the <code>show</code> state.</p>
        <p>The state must follow a single source of truth: the state is maintained by the parent, and modal is just passed down the value of show.</p>
        <br />
        <Button variant="primary" size="sm" onClick={() => this.setState({show: true})}>Open Modal</Button>
        <br />
        <Modal show={this.state.show} onHide={() => this.setState({show: false})}>
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Modal body text goes here.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" className="mr-auto" onClick={() => this.setState({show: false})}>Tertiary</Button>
            <Button variant="secondary" onClick={() => this.setState({show: false})}>Close</Button>
            <Button variant="primary" onClick={() => this.setState({show: false})}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <br />
      </>
    );

    const codeString_button = `
    <Modal show={this.state.show} onHide={() => this.setState({show: false})}>
      <Modal.Header closeButton>
        <Modal.Title>Modal title</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Modal body text goes here.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="mr-auto" onClick={() => this.setState({show: false})}>Tertiary</Button>
        <Button variant="secondary" onClick={() => this.setState({show: false})}>Close</Button>
        <Button variant="primary" onClick={() => this.setState({show: false})}>Submit</Button>
      </Modal.Footer>
    </Modal>
    `;

    const codes = [
      {
        code: code_basic,
        codeLanguage: "html",
        codeString: codeString_basic
      },
      {
        code: code_button,
        codeLanguage: "html",
        codeString: codeString_button
      }
    ]

    const accessibilitySection = (
      <>
        <h5>Checkboxes Must Be Keyboard Navigable</h5>
        <p>Since a checkbox is an interactive control, it must be focusable and keyboard accessible. If the role is applied to a non-focusable element, use the tabindex attribute to change this. The expected keyboard shortcut for activating a checkbox is the Space key.</p>

        <h5>Make the Label Clickable</h5>
        <p>
          In the above examples, you may have noticed that you can toggle a checkbox by clicking on its associated <code>&lt;label&gt;</code> element as well as on the checkbox itself. This is a really useful feature of HTML form labels that makes it easier to click the option you want, especially on small-screen devices like smartphones. Beyond accessibility, this is another good reason to properly set up <code>&lt;label&gt;</code> elements on your forms.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Modals"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
